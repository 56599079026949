<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>

    <div id="main-wrapper">
      <NavSide sayfa="Mobil Bildirimler" />
      <div class="content-body">
        <!-- row -->
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="row mb-5 align-items-center">
                <div class="col-lg-3 mb-4 mb-lg-0">
                  <a
                    @click="ekle1"
                    class="
                      btn btn-primary
                      light
                      btn-lg btn-block
                      rounded
                      shadow
                      px-2
                    "
                    >+ Bildirim Gönder</a
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="tab-content">
                <div id="navpills-1" class="tab-pane active">
                  <div class="table-responsive table-hover fs-14">
                    <table
                      class="table display mb-4 dataTablesCard fs-14"
                      id="example5"
                    >
                      <thead>
                        <tr>
                          <th class="d-none d-lg-table-cell">Bildirim</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="bildirim in bildirimlist" :key="bildirim.bildirimId">
                          <td>
                            <div class="media align-items-center">
                              <!-- <img
                                class="
                                  img-fluid
                                  rounded
                                  mr-3
                                  d-none d-xl-inline-block
                                "
                                width="70"
                                :src="bildirim.kapak"
                                alt="zeplinx"
                              /> -->
                              <div class="media-body">
                                <h4 class="text-black font-w600 mb-1 wspace-no">
                                  {{ bildirim.bildirimAdi }}
                                </h4>
                                <span>{{ bildirim.bildirimBilgi }}</span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div class="d-flex beyaz">
                              <a
                                @click="duzenle(bildirim, 'duzenle')"
                                class="btn btn-primary btn-sm px-4"
                                >Tekrar Gönder</a
                              >
                              <!-- <a
                                @click="duzenle(bildirim, 'sil')"
                                class="btn btn-danger btn-sm ml-2 px-4"
                                >Sil</a
                              > -->
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Bildirim Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    style="overflow-y: scroll; height: 400px"
                    v-if="bodykont"
                  >
                    <!-- <profilresim :imageurl="BildirimBilgiler.kapak" />
                    <imageresize
                      @dosyaisim="dsyisim = $event"
                      @resim="BildirimBilgiler.kapak = $event"
                    />
                    <dosyasecbtn :dosyaismi="dsyisim" /> -->
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Bildirim Başlığı"
                        v-model="BildirimBilgiler.bildirimAdi"
                      />
                    </div>
                    <div class="basic-form">
                      <form>
                        <div class="form-group">
                          <textarea v-model="BildirimBilgiler.bildirimBilgi" cols="30" rows="10" type="textarea"
                        class="form-control input-default" placeholder="Bildirim Açıklaması">

                          </textarea>
                          <!-- <VueEditor v-model="BildirimBilgiler.bildirimBilgi" /> -->
                        </div>
                        <!-- <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Bildirim Tarih"
                          v-model="BildirimBilgiler.bildirimTarih"
                        /> -->
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="bildirimguncelle()"
                  >
                   TEKRAR GÖNDER
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Bildirim Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    style="overflow-y: scroll; height: 400px"
                    v-if="bodykont"
                  >
                    <!-- <profilresim :imageurl="BildirimBilgiler.kapak" />
                    <imageresize
                      @dosyaisim="dsyisim = $event"
                      @resim="BildirimBilgiler.kapak = $event"
                    />
                    <dosyasecbtn :dosyaismi="dsyisim" /> -->
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Bildirim Başlığı"
                        v-model="BildirimBilgiler.bildirimAdi"
                      />
                    </div>
                    <div class="basic-form">
                      <form>
                        <div class="form-group">

                          <textarea v-model="BildirimBilgiler.bildirimBilgi" cols="30" rows="10" type="textarea"
                        class="form-control input-default" placeholder="Bildirim Açıklaması">

                          </textarea>
                          <!-- <VueEditor v-model="BildirimBilgiler.bildirimBilgi" /> -->
                        </div>
                        <!-- <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Bildirim Tarih"
                          v-model="BildirimBilgiler.bildirimTarih"
                        /> -->
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="bildirimekle()"
                  >
                    GÖNDER
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Bildirim Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ BildirimBilgiler.bildirimAdi }} isimli bildirimi silmek istiyor
                    musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// import profilresim from "@/components/baskan/profilresim.vue";
// import imageresize from "@/components/yardimcilar/imageresize.vue";
// import dosyasecbtn from "@/components/yardimcilar/dosyasecbtn.vue";
import NavSide from "@/components/home/nav-side.vue";
import axios from "axios"
export default {
  name: "Bildirimler",
  data() {
    return {
      BildirimBilgiler: {
        bildirimAdi: "",
        bildirimBilgi: "",
        bildirimId: "",
        // kapak: "",
      },
      MobilGenelBilgiler: {
      },
      prekontrol: false,
      bodykont: true,
      dsyisim: "Resim Seç",
      showYeniModal: false,
      showModal: false,
      showAlert: false,
      kontrol: true,
      bildirimlist: [],
      Bildirimler: {},
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Bildirimler");
    this.Bildirimler = this.$store.getters.getBildirimler;
    this.bildirimlist = Object.values(this.Bildirimler);
    this.bildirimlist.sort(function (b, a) {
      return a.bildirimId - b.bildirimId;
    });
    await this.$store.dispatch("vericekme", "MobilGenel");
    this.MobilGenelBilgiler = {
      ...this.MobilGenelBilgiler,
      ...this.$store.getters.getMobilGenel,
    };
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  components: {
    NavSide,
    // profilresim,
    // imageresize,
    // dosyasecbtn,
  },
  methods: {
    sendNotification() {
       axios.post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            title: this.BildirimBilgiler.bildirimAdi,
            body: this.BildirimBilgiler.bildirimBilgi,
          },
          to: "/topics/topic",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.MobilGenelBilgiler.notificationAPI
          },
        }
      )
      .then(() => {
        this.$root.$refs.toast.toastgoster("basari", "Bildirim Gönderildi.");
        
      })
      .catch(() => {
        this.$root.$refs.toast.toastgoster("hata", "Bildirim Gönderilemedi API Anahtarını kontrol ediniz.");
      });
    },
    ekle1() {
      this.showYeniModal = true;
      this.BildirimBilgiler = {};
    },
    async sil() {
      const veribilgiler = {
        dosyakontrol: false,
        kapak: this.BildirimBilgiler.kapak,
        col: "Bildirimler",
        key: "Bildirimler" + "." + this.BildirimBilgiler.bildirimId,
        dataekle: this.BildirimBilgiler,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Bildirim Silindi");
      this.Bildirimler = this.$store.getters.getBildirimler;
      this.bildirimlist = Object.values(this.Bildirimler);
      this.bildirimlist.sort(function (b, a) {
        return a.bildirimId - b.bildirimId;
      });
    },
    async bildirimguncelle() {
      this.bodykont = false;
      this.prekontrol = true;

      const dataekle = {
        col: "Bildirimler",
        key: "Bildirimler" + "." + this.BildirimBilgiler.bildirimId,
        dataekle: this.BildirimBilgiler,
      };
      this.sendNotification()
      await this.$store.dispatch("veriguncelle", dataekle);

      this.showModal = false;
      this.prekontrol = false;
      this.bodykont = true;
      this.$root.$refs.toast.toastgoster("basari", "Tekrar Gönderme Başarılı");
      this.dsyisim = "Resim Seç";
      this.Bildirimler = this.$store.getters.getBildirimler;
      this.bildirimlist = Object.values(this.Bildirimler);
      this.bildirimlist.sort(function (b, a) {
        return a.bildirimId - b.bildirimId;
      });
      this.BildirimBilgiler = {};
    },

    async bildirimekle() {
      const yenibildirimId = Date.now().toString();
        this.bodykont = false;
        this.prekontrol = true;
        const yenidata = this.BildirimBilgiler;
        yenidata["bildirimId"] = yenibildirimId;
        const dataekle = {
          dosyakontrol: false,
          // dosyalar: [this.BildirimBilgiler.kapak],
          // kapak: this.BildirimBilgiler.kapak,
          col: "Bildirimler",
          key: "Bildirimler" + "." + yenibildirimId,
          ref: "Bildirimler" + "/" + yenibildirimId,
          dataekle: this.BildirimBilgiler,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
        this.Bildirimler = this.$store.getters.getBildirimler;
        this.bildirimlist = Object.values(this.Bildirimler);
        this.bildirimlist.sort(function (b, a) {
          return a.bildirimId - b.bildirimId;
        });
        this.sendNotification()
        this.BildirimBilgiler = {};
        this.bodykont = true;
        this.prekontrol = false;
        this.showYeniModal = false;
        this.dsyisim = "Resim Seç";
        // this.$root.$refs.toast.toastgoster("basari", "Başarıyla Eklendi.");
    },

    duzenle(bildirim, hangisi) {
      this.BildirimBilgiler = bildirim;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.showModal = true;
      }
    },
  },

  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>