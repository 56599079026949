<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
        <p>{{ progress }} yükleniyor</p>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Başkan Genel Bilgiler" />
      <div class="content-body">
        <div class="container-fluid">
          <div class="card">
            <profilresim :imageurl="BaskanBilgileri.kapak" />
            <imageresize
              @dosyaisim="dsyisim = $event"
              @resim="BaskanBilgileri.kapak = $event"
            />
            <dosyasecbtn :dosyaismi="dsyisim" />
            <div class="col-xl-10 col-lg-10">
              <div class="basic-form">
                <form>
                  <div class="form-group">
                    <span class="solGenelBilgi">Başkan Adı</span>
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Başkan Adı"
                      v-model="BaskanBilgileri.baskanadi"
                    />
                  </div>
                  <div class="form-group">
                    <span class="solGenelBilgi">Başkan Slogan</span>
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Başkan Slogan"
                      v-model="BaskanBilgileri.baskanslogan"
                    />
                  </div>
                  <div class="form-group">
                    <span class="solGenelBilgi">Başkan Facebook Link</span>
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Başkan Facebook Hesabı"
                      v-model="BaskanBilgileri.baskanface"
                    />
                  </div>
                  <div class="form-group">
                    <span class="solGenelBilgi">Başkan Twitter Link</span>
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Başkan Twitter Hesabı"
                      v-model="BaskanBilgileri.baskantwit"
                    />
                  </div>
                  <div class="form-group">
                    <span class="solGenelBilgi">Başkan Instagram Link</span>
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Başkan İnstagram Hesabı"
                      v-model="BaskanBilgileri.baskaninsta"
                    />
                  </div>
                </form>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-outline-primary ml-4 mr-4"
              @click="baskangenelfonk()"
            >
              Güncelle
            </button>
            <div class="bosluk"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import profilresim from "@/components/baskan/profilresim.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import dosyasecbtn from "@/components/yardimcilar/dosyasecbtn.vue";
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "Baskangenel",
  data() {
    return {
      BaskanBilgileri: {
        baskanadi: "",
        baskanslogan: "",
        baskanface: "",
        baskantwit: "",
        baskaninsta: "",
        kapak: "",
        ozgecmis: "",
        mesaj: "",
        galeri: [],
      },
      kontrol: true,
      dsyisim: "Resim Seçiniz.",
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Baskan");
    this.BaskanBilgileri = this.$store.getters.getBaskan;
  },
  components: {
    profilresim,
    imageresize,
    dosyasecbtn,
    NavSide,
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  methods: {
    async baskangenelfonk() {
      if (this.BaskanBilgileri.baskanadi == "") {
        this.$root.$refs.toast.toastgoster("hata", "Başkan adı giriniz !!!");
      } else if (this.BaskanBilgileri.baskanslogan == "") {
        this.$root.$refs.toast.toastgoster("hata", "Başkan slogan giriniz !!!");
      } else if (this.BaskanBilgileri.baskanface == "") {
        this.$root.$refs.toast.toastgoster(
          "hata",
          "Başkan facebook giriniz !!!"
        );
      } else if (this.BaskanBilgileri.baskantwit == "") {
        this.$root.$refs.toast.toastgoster(
          "hata",
          "Başkan twitter hesabı giriniz !!!"
        );
      } else if (this.BaskanBilgileri.baskaninsta == "") {
        this.$root.$refs.toast.toastgoster(
          "hata",
          "Başkan instagram hesabı giriniz !!!"
        );
      } else {
        if (this.dsyisim != "Resim Seçiniz.") {
          const dataekle = {
            dosyakontrol: false,
            dosyalar: [this.BaskanBilgileri.kapak],
            kapak: this.BaskanBilgileri.kapak,
            col: "Baskan",
            key: "Baskan",
            ref: "Baskan" + "/" + "kapak",
            dataekle: this.BaskanBilgileri,
          };
          this.kontrol = false;
          document.getElementById("main-wrapper").style.opacity = "0";
          await this.$store.dispatch("veriguncelledosyali", dataekle);
          this.kontrol = true;
          document.getElementById("main-wrapper").style.opacity = "1";
          this.$root.$refs.toast.toastgoster(
            "basari",
            "Güncelleme Fotoğraflı Başarılı"
          );
        } else {
          const dataekle = {
            col: "Baskan",
            key: "Baskan",
            dataekle: this.BaskanBilgileri,
          };
          await this.$store.dispatch("veriguncelle", dataekle);
          this.$root.$refs.toast.toastgoster("basari", "Güncelleme Başarılı");
        }
        this.dsyisim = "Resim Seçiniz.";
      }
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.genelcss {
  padding-left: 60px;
}
.bosluk {
  padding-bottom: 100px;
}
.bosluk2 {
  padding-bottom: 50px;
}
.guncellebtn {
  padding-left: 50%;
  padding-right: 50%;
}
</style>