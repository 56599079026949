<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="PratikRehber" />
      <div class="content-body">
        <!-- row -->
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="row mb-5 align-items-center">
                <div class="col-lg-3 mb-4 mb-lg-0">
                  <a
                    @click="date1('ekle')"
                    class="
                      btn btn-primary
                      light
                      btn-lg btn-block
                      rounded
                      shadow
                      px-2
                    "
                    >+ Rehber Ekle</a
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="tab-content">
                <div id="navpills-1" class="tab-pane active">
                  <div class="table-responsive table-hover fs-14">
                    <table
                      class="table display mb-4 dataTablesCard fs-14"
                      id="example5"
                    >
                      <thead>
                        <tr>
                          <th>Rehber Adı</th>
                          <th class="d-none d-lg-table-cell">
                            Oluşturulma Tarihi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(Pratik, index) in Pratiklist" :key="index">
                          <td>
                            <div class="media align-items-center">
                              <img
                                class="
                                  img-fluid
                                  rounded
                                  mr-3
                                  d-none d-xl-inline-block
                                "
                                width="70"
                                :src="Pratik.kapak"
                                alt="zeplinx"
                              />
                              <div
                                class="media-body"
                                style="padding-right: 20px"
                              >
                                <h4 class="text-black font-w600 mb-1 wspace-no">
                                  {{ Pratik.pratikAdi.substring(0, 30) }}..
                                </h4>
                              </div>
                            </div>
                          </td>
                          <td class="d-none d-lg-table-cell">
                            {{ Pratik.pratikTarihi }}
                          </td>
                          <td>
                            <div class="d-flex beyaz">
                              <a
                                @click="duzenle(Pratik, 'duzenle')"
                                class="btn btn-primary btn-sm px-4"
                                >Düzenle</a
                              >
                              <a
                                @click="duzenle(Pratik, 'sil')"
                                class="btn btn-danger btn-sm ml-2 px-4"
                                >Sil</a
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Rehber Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    v-if="bodykont"
                    class="row"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <div class="col-xl-6 col-lg-6">
                      <div class="form-group">
                        <p class="mb-1">Rehber Adı</p>
                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Rehber Adı Giriniz"
                          v-model="PratikBilgisi.pratikAdi"
                        />
                      </div>
                      <div class="form-group">
                        <p class="mb-1">Rehber Açıklaması</p>
                        <textarea
                          type="text"
                          class="form-control input-default"
                          placeholder="Rehber Açıklaması Giriniz"
                          v-model="PratikBilgisi.pratikAciklamasi"
                        ></textarea>
                      </div>
                      <p class="mb-1">Rehber Tarihi Seç</p>
                      <input
                        name="datepicker"
                        class="datepicker-default form-control"
                        :placeholder="PratikBilgisi.pratikTarihi"
                        id="datepicker"
                      />
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <p>Kapak Resmi</p>
                      <profilresim :imageurl="PratikBilgisi.kapak" />
                      <kapaksec :dosyaismi="dsykapak" />
                      <imageresizekapak
                        @dosyaisim="dsykapak = $event"
                        @resim="PratikBilgisi.kapak = $event"
                      />
                      <imageresize
                        @gorunen="gorunenler = $event"
                        @resimler="resimler = $event"
                      />
                      <cokludosyasec :dosyaismi="dsyisim" />
                      <p>Yüklenecekler</p>
                      <div class="right-body">
                        <div class="message">
                          <div class="son-images">
                            <div class="inside-img">
                              <img
                                v-for="(item, index) in gorunenler"
                                :key="index"
                                :src="item"
                                alt
                              />
                              <button
                                v-if="resimler.length > 5"
                                class="son-buton"
                              >
                                {{ resimler.length - 5 }} +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="Pratikekle()"
                  >
                    EKLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Rehber Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    v-if="bodykont"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <div class="row">
                      <div class="col-xl-6 col-lg-6">
                        <div class="form-group">
                          <p class="mb-1">Rehber Adı</p>
                          <input
                            type="text"
                            class="form-control input-default"
                            placeholder="Rehber Adı Giriniz"
                            v-model="PratikBilgisi.pratikAdi"
                          />
                        </div>
                        <div class="form-group">
                          <p class="mb-1">Rehber Açıklaması</p>
                          <textarea
                            type="text"
                            class="form-control input-default"
                            placeholder="Rehber Açıklaması Giriniz"
                            v-model="PratikBilgisi.pratikAciklamasi"
                          ></textarea>
                        </div>
                        <p class="mb-1">Rehber Tarihi Seç</p>
                        <input
                          name="datepicker"
                          class="datepicker-default form-control"
                          :placeholder="PratikBilgisi.pratikTarihi"
                          id="datepicker"
                        />
                        <p>Yüklü Olanlar</p>
                        <div class="right-body">
                          <div class="message">
                            <div class="son-images">
                              <div class="inside-img">
                                <img
                                  v-for="(
                                    resim, index
                                  ) in PratikBilgisi.Dosyalar"
                                  :key="index"
                                  :src="resim.dosyaurl"
                                  @click="
                                    silindex = index;
                                    showAlertResim = true;
                                  "
                                  alt
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6">
                        <p>Kapak Resmi</p>
                        <profilresim :imageurl="PratikBilgisi.kapak" />
                        <kapaksec :dosyaismi="dsykapak" />
                        <imageresizekapak
                          @dosyaisim="dsykapak = $event"
                          @resim="PratikBilgisi.kapak = $event"
                        />
                        <imageresize
                          @gorunen="gorunenler = $event"
                          @resimler="resimler = $event"
                        />
                        <cokludosyasec :dosyaismi="dsyisim" />
                        <p>Yüklenecekler</p>
                        <div class="right-body">
                          <div class="message">
                            <div class="son-images">
                              <div class="inside-img">
                                <img
                                  v-for="(item, index) in gorunenler"
                                  :key="index"
                                  :src="item"
                                  alt
                                />
                                <button
                                  v-if="resimler.length > 5"
                                  class="son-buton"
                                >
                                  {{ resimler.length - 5 }} +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="showAlertResim">
                      <transition name="modal">
                        <div class="modal-mask">
                          <div class="modal-wrapper">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Resim Silme</h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span
                                      aria-hidden="true"
                                      @click="showAlertResim = false"
                                      >&times;</span
                                    >
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Resmi silmek istiyor musunuz ?</p>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="dosyasil()"
                                  >
                                    Evet SiL
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="showAlertResim = false"
                                  >
                                    VAZGEÇ
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="Pratikguncelle()"
                  >
                    GÜNCELLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Rehber Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ PratikBilgisi.pratikAdi }} isimli Rehberi silmek istiyor
                    musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import imageresizekapak from "@/components/yardimcilar/imageresizekapak.vue";
import cokludosyasec from "@/components/yardimcilar/cokludosyasec.vue";
import profilresim from "@/components/baskan/profilresim.vue";
import kapaksec from "@/components/yardimcilar/kapaksec.vue";
export default {
  name: "PratikRehber",
  data() {
    return {
      silindex: 0,
      dsykapak: "Kapak Resmi Seç",
      dsyisim: "Resim Seç",
      prekontrol: false,
      bodykont: true,
      showModal: false,
      showYeniModal: false,
      showAlert: false,
      showAlertResim: false,
      gorunenler: [],
      resimler: [],
      PratikBilgisi: {
        pratikAdi: "",
        pratikTarihi: "",
        pratikId: "",
        kapak: "",
        pratikAciklamasi: "",
        Dosyalar: [],
      },
      PratikRehber: {},
      Pratiklist: [],
      kontrol: true,
    };
  },
  components: {
    NavSide,
    imageresize,
    imageresizekapak,
    cokludosyasec,
    profilresim,
    kapaksec,
  },
  async created() {
    await this.$store.dispatch("vericekme", "PratikRehber");
    this.PratikRehber = this.$store.getters.getPratikRehber;
    this.Pratiklist = Object.values(this.PratikRehber);
    this.Pratiklist.sort(function (b, a) {
      return a.pratikId - b.pratikId;
    });

  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  methods: {
    date1(hangisi) {
      if (hangisi == "ekle") {
        this.PratikBilgisi = {};
        this.showYeniModal = true;
      } else {
        this.showModal = true;
      }
      let datepickscr5 = document.createElement("script");
      datepickscr5.setAttribute("src", "js/plugins-init/pickadate-init.js");
      document.head.appendChild(datepickscr5);
    },
    duzenle(Pratik, hangisi) {
      this.PratikBilgisi = Pratik;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.date1("duzenle");
      }
      this.dsykapak == "Kapak Resmi Seç";
    },
    async Pratikekle() {
      const tarih = document.querySelector("#datepicker").value;
      if (
        this.PratikBilgisi.pratikAdi == "" ||
        this.PratikBilgisi.pratikAdi == undefined
      ) {
        this.$root.$refs.toast.toastgoster("hata", "Rehber Adı Boş Olamaz");
      } else if (tarih == "" || tarih == undefined) {
        this.$root.$refs.toast.toastgoster("hata", "Rehber Tarihi Boş Olamaz");
      } else {
        const yeniId = Date.now().toString();
        if (this.dsykapak != "Resim Seç") {
          const dataekle = {
            kapak: this.PratikBilgisi.kapak,
            dosyakontrol: true,
            dosyalar: this.resimler,
            col: "PratikRehber",
            ref: "PratikRehber" + "/" + yeniId,
            key: "PratikRehber" + "." + yeniId,
            dataekle: {
              pratikAdi: this.PratikBilgisi.pratikAdi,
              pratikId: yeniId,
              pratikTarihi: tarih,
              pratikAciklamasi: this.PratikBilgisi.pratikAciklamasi,
              Dosyalar: [],
            },
          };
          this.bodykont = false;
          this.prekontrol = true;
          await this.$store.dispatch("veriguncelledosyali", dataekle);
        } else {
          const dataekle = {
            col: "PratikRehber",
            key: "PratikRehber" + "." + yeniId,
            dataekle: {
              pratikAdi: this.PratikBilgisi.pratikAdi,
              pratikId: yeniId,
              pratikTarihi: tarih,
              Dosyalar: [],
            },
          };
          this.bodykont = false;
          this.prekontrol = true;
          await this.$store.dispatch("veriguncelle", dataekle);
        }
        this.PratikRehber = this.$store.getters.getPratikRehber;
        this.Pratiklist = Object.values(this.PratikRehber);
        this.Pratiklist.sort(function (b, a) {
          return a.pratikId - b.pratikId;
        });
        this.bodykont = true;
        this.prekontrol = false;
        this.showYeniModal = false;
        this.resimler = [];
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Rehber Başarılı Bir Şekilde Eklendi."
        );
        this.PratikBilgisi = {};
        this.resimler = [];
        this.gorunenler = [];
      }
    },
    async Pratikguncelle() {
      var tarih = document.querySelector("#datepicker").value;
      if (
        this.PratikBilgisi.pratikAdi == "" ||
        this.PratikBilgisi.pratikAdi == undefined
      ) {
        this.$root.$refs.toast.toastgoster("hata", "Rehber Adı Boş Olamaz");
      } else {
        if (tarih == "" || tarih == undefined) {
          console.log("tarih bos");
          tarih = this.PratikBilgisi.pratikTarihi;
        } else {
          this.PratikBilgisi.pratikTarihi = tarih;
        }
        if (this.resimler.length > 0) {
          if (this.dsykapak == "Kapak Resmi Seç") {
            const dataekle = {
              kapak: "ekli",
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "PratikRehber",
              ref: "PratikRehber" + "/" + this.PratikBilgisi.pratikId,
              key: "PratikRehber" + "." + this.PratikBilgisi.pratikId,
              dataekle: {
                kapak: this.PratikBilgisi.kapak,
                pratikAdi: this.PratikBilgisi.pratikAdi,
                pratikId: this.PratikBilgisi.pratikId,
                pratikTarihi: tarih,
                pratikAciklamasi: this.PratikBilgisi.pratikAciklamasi,
                Dosyalar: this.PratikBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
          } else {
            const dataekle = {
              kapak: this.PratikBilgisi.kapak,
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "PratikRehber",
              ref: "PratikRehber" + "/" + this.PratikBilgisi.pratikId,
              key: "PratikRehber" + "." + this.PratikBilgisi.pratikId,
              dataekle: {
                kapak: this.PratikBilgisi.kapak,
                pratikAdi: this.PratikBilgisi.pratikAdi,
                pratikId: this.PratikBilgisi.pratikId,
                pratikTarihi: tarih,
                pratikAciklamasi: this.PratikBilgisi.pratikAciklamasi,
                Dosyalar: this.PratikBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
          }
        } else {
          if (this.dsykapak != "Kapak Resmi Seç") {
            const dataekle = {
              kapak: this.PratikBilgisi.kapak,
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "PratikRehber",
              ref: "PratikRehber" + "/" + this.PratikBilgisi.pratikId,
              key: "PratikRehber" + "." + this.PratikBilgisi.pratikId,
              dataekle: {
                kapak: this.PratikBilgisi.kapak,
                pratikAdi: this.PratikBilgisi.pratikAdi,
                pratikId: this.PratikBilgisi.pratikId,
                pratikTarihi: tarih,
                pratikAciklamasi: this.PratikBilgisi.pratikAciklamasi,
                Dosyalar: this.PratikBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
          } else {
            const dataekle = {
              dosyakontrol: false,
              col: "PratikRehber",
              key: "PratikRehber" + "." + this.PratikBilgisi.pratikId,
              dataekle: {
                kapak: this.PratikBilgisi.kapak,
                pratikAdi: this.PratikBilgisi.pratikAdi,
                pratikId: this.PratikBilgisi.pratikId,
                pratikTarihi: tarih,
                pratikAciklamasi: this.PratikBilgisi.pratikAciklamasi,
                Dosyalar: this.PratikBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelle", dataekle);
          }
        }
        this.PratikRehber = this.$store.getters.getPratikRehber;
        this.Pratiklist = Object.values(this.PratikRehber);
        this.Pratiklist.sort(function (b, a) {
          return a.pratikId - b.pratikId;
        });
        this.bodykont = true;
        this.prekontrol = false;
        this.showModal = false;
        this.resimler = [];
        this.gorunenler = [];
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Rehber Başarılı Bir Şekilde Eklendi."
        );
        this.PratikBilgisi = {};
        this.resimler = [];
        this.gorunenler = [];
      }
    },
    async dosyasil() {
      const dosya = this.PratikBilgisi.Dosyalar[this.silindex];
      const url = dosya.dosyaurl;

      const dataekle = {
        url: url,
        col: "PratikRehber",
        key: "PratikRehber" + "." + this.PratikBilgisi.pratikId,
        dataekle: this.PratikBilgisi,
      };
      await this.$store.dispatch("veridosyasilme", dataekle);
      this.PratikBilgisi.Dosyalar.splice(this.silindex, 1);
      await this.$store.dispatch("veriguncelle", dataekle);
      this.showAlertResim = false;
    },
    async sil() {
      const veribilgiler = {
        kapak: this.PratikBilgisi.kapak,
        dosyakontrol: true,
        col: "PratikRehber",
        key: "PratikRehber" + "." + this.PratikBilgisi.pratikId,
        dataekle: this.PratikBilgisi,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Karar Silindi");
      this.PratikRehber = this.$store.getters.getPratikRehber;
      this.Pratiklist = Object.values(this.PratikRehber);
      this.Pratiklist.sort(function (b, a) {
        return a.pratikId - b.pratikId;
      });
      this.PratikBilgisi = {};
      this.resimler = [];
      this.gorunenler = [];
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    let datepickscr = document.createElement("script");
    datepickscr.setAttribute("src", "vendor/pickadate/picker.js");
    document.head.appendChild(datepickscr);
    let datepickscr2 = document.createElement("script");
    datepickscr2.setAttribute("src", "vendor/pickadate/picker.time.js");
    document.head.appendChild(datepickscr2);
    let datepickscr4 = document.createElement("script");
    datepickscr4.setAttribute("src", "vendor/pickadate/picker.date.js");
    document.head.appendChild(datepickscr4);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.right-side {
  background-color: #f2f3f7;
  width: 100%;
  padding: 8px 30px;
  display: flex;
  flex-direction: column;
}
.right-body {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.message {
  margin: 20px 10px;
  flex: 1;
  background-color: white;
  padding: 25px;
  overflow: auto;
}

.son-images {
  display: flex;
  align-items: center;

  width: 100%;
}
.inside-img > img {
  width: 100px;
  height: 100px;
  padding-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
}
.inside-img > img:hover {
  transform: scale(0.95);
}
.son-buton {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px;
  margin-top: 17px;
  background-color: #f5e9f9;
  color: #b79ed8;
  font-weight: 500;
  font-size: 20px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.secbtn {
  padding-top: 20px;
}
</style>