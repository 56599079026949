<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Meclis Kararları" />
      <div class="content-body">
        <!-- row -->
        <link href="vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
        <link
          href="vendor/datatables/css/jquery.dataTables.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css"
          rel="stylesheet"
        />
        <link href="vendor/dropzone/dist/dropzone.css" rel="stylesheet" />
        <link href="css/style.css" rel="stylesheet" />
        <div class="container-fluid">
          <div class="row mb-5 align-items-center">
            <div class="col-lg-3 mb-4 mb-lg-0">
              <a
                class="btn btn-primary light btn-lg btn-block rounded shadow px-2"
                @click="date1('ekle')"
                >+ Karar Ekle</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table
                  id="example5"
                  class="table display mb-4 dataTablesCard fs-14"
                >
                  <thead>
                    <tr>
                      <th>Karar Adı</th>
                      <th>Oluşturulma Tarihi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(karar, index) in kararlist" :key="index">
                      <td>{{ karar.kararAdi }}</td>
                      <td>{{ karar.kararTarihi }}</td>
                      <td class="d-flex">
                        <a @click="duzenle(karar, 'duzenle')">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.2557 2.99994C17.5201 2.73729 17.8341 2.52895 18.1796 2.38681C18.5251 2.24467 18.8954 2.17151 19.2694 2.17151C19.6433 2.17151 20.0136 2.24467 20.3591 2.38681C20.7046 2.52895 21.0186 2.73729 21.283 2.99994C21.5474 3.26258 21.7572 3.57438 21.9003 3.91754C22.0434 4.2607 22.1171 4.6285 22.1171 4.99994C22.1171 5.37137 22.0434 5.73917 21.9003 6.08233C21.7572 6.42549 21.5474 6.73729 21.283 6.99994L7.69086 20.4999L2.15332 21.9999L3.66356 16.4999L17.2557 2.99994Z"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                        <a @click="duzenle(karar, 'sil')" class="ml-4">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.50195 5.99994H5.5156H21.6248"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Yeni Karar Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="progreskontrol">{{ progress }}</p>
                  <div
                    v-if="bodykontrol"
                    class="row"
                    style="overflow-y: scroll; height:400px;"
                  >
                    <div class="col-xl-6 col-lg-6">
                      <div class="form-group">
                        <p class="mb-1">Karar Adı</p>
                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Karar Adı Giriniz"
                          v-model="KararBilgisi.kararAdi"
                        />
                      </div>
                      <p class="mb-1">Karar Tarihi Seç</p>
                      <input
                        name="datepicker"
                        class="datepicker-default form-control"
                        placeholder="Karar Tarihi Seçilmedi"
                        id="datepicker"
                      />
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <tr v-for="(dosya, indxx) in dosyalar" :key="indxx">
                        <td class="d-flex">{{ dosya.name }}</td>
                        <td>
                          <a @click="dosyasil(indxx)" class="ml-4">
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.50195 5.99994H5.5156H21.6248"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <div class="secbtn">
                        <div class="col-xl-10 col-lg-10">
                          <div class="basic-form custom_file_input">
                            <div class="input-group mb-3">
                              <div class="custom-file">
                                <input
                                  placeholder="Karar Dosyası Seçiniz."
                                  id="file"
                                  type="file"
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                  @change="dosyasecme"
                                  multiple="true"
                                  class="custom-file-input"
                                />
                                <label class="custom-file-label"
                                  >Karar Dosyası Seçiniz.</label
                                >
                              </div>
                              <div class="input-group-append">
                                <span class="input-group-text">Yükle</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="kararekle()"
                  >
                    EKLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Karar Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="progreskontrol">{{ progress }}</p>
                  <div
                    v-if="bodykontrol"
                    class="row"
                    style="overflow-y: scroll; height:400px;"
                  >
                    <div class="col-xl-6 col-lg-6">
                      <div class="form-group">
                        <p class="mb-1">Karar Adı</p>
                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Karar Adı Giriniz"
                          v-model="KararBilgisi.kararAdi"
                        />
                      </div>
                      <p class="mb-1">Karar Tarihi Seç</p>
                      <input
                        name="datepicker"
                        class="datepicker-default form-control"
                        :placeholder="KararBilgisi.kararTarihi"
                        id="datepicker"
                      />
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <p class="mb-1">Yüklü Dosyalar</p>
                      <tr
                        v-for="(dosya, indxx) in KararBilgisi.Dosyalar"
                        :key="indxx"
                      >
                        <td class="d-flex">{{ dosya.dosyaTarihi }}</td>
                        <td>
                          <a @click="dosyasil(indxx, 'yuklu')" class="ml-4">
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.50195 5.99994H5.5156H21.6248"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <p v-if="dosyalar.length > 0" class="mb-1">
                        Yüklenecek Dosyalar
                      </p>
                      <tr v-for="(dosya, indxx) in dosyalar" :key="dosya.name">
                        <td class="d-flex">{{ dosya.name }}</td>
                        <td>
                          <a
                            @click="dosyasil(indxx, 'yuklenecek')"
                            class="ml-4"
                          >
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.50195 5.99994H5.5156H21.6248"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <div class="secbtn">
                        <div class="col-xl-10 col-lg-10">
                          <div class="basic-form custom_file_input">
                            <div class="input-group mb-3">
                              <div class="custom-file">
                                <input
                                  id="file"
                                  type="file"
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                  @change="dosyasecme"
                                  multiple="true"
                                  class="custom-file-input"
                                />
                                <label class="custom-file-label"
                                  >Dosya Seç</label
                                >
                              </div>
                              <div class="input-group-append">
                                <span class="input-group-text">Yükle</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="guncelle()"
                  >
                    GÜNCELLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Karar Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ KararBilgisi.kararAdi }} isimli kararı silmek istiyor
                    musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "MeclisKararlari",
  data() {
    return {
      dosyaismi: "Dosya Seç",
      kararlist: [],
      KararBilgisi: {
        kararAdi: "",
        kararId: "",
        kararTarihi: "",
        Dosyalar: [
          {
            dosyaAdi: "",
            dosyaId: "",
            dosyaTarihi: "",
            dosyaurl: "",
          },
        ],
      },
      showYeniModal: false,
      showModal: false,
      showAlert: false,
      kontrol: true,
      bodykontrol: true,
      progreskontrol: false,
      dosyalar: [],
      kararlar: {},
    };
  },

  async created() {
    await this.$store.dispatch("vericekme", "Kararlar");
    this.kararlar = this.$store.getters.getKararlar;
    this.kararlist = Object.values(this.kararlar);
    this.kararlist.sort(function(b, a) {
      return a.kararId - b.kararId;
    });
  },
  components: {
    NavSide,
  },
  methods: {
    date1(hangisi) {
      if (hangisi == "ekle") {
        this.KararBilgisi = {};
        this.showYeniModal = true;
      } else {
        this.showModal = true;
      }
      let datepickscr5 = document.createElement("script");
      datepickscr5.setAttribute("src", "js/plugins-init/pickadate-init.js");
      document.head.appendChild(datepickscr5);
    },
    async dosyasil(indxx, hangisi) {
      if (hangisi == "yuklu") {
        const dosya = this.KararBilgisi.Dosyalar[indxx];
        const url = dosya.dosyaurl;

        const dataekle = {
          url: url,
          col: "Kararlar",
          key: "Kararlar" + "." + this.KararBilgisi.kararId,
          dataekle: this.KararBilgisi,
        };
        await this.$store.dispatch("veridosyasilme", dataekle);
        this.KararBilgisi.Dosyalar.splice(indxx, 1);
        await this.$store.dispatch("veriguncelle", dataekle);
      } else {
        console.log(this.dosyalar);
        const test1 = Array.from(this.dosyalar);
        test1.splice(indxx, 1);
        this.dosyalar = test1;
      }
    },
    dosyasecme(e) {
      const files = e.target.files;
      this.dosyalar = files;
    },
    async sil() {
      const veribilgiler = {
        kapak: "",
        dosyakontrol: true,
        col: "Kararlar",
        key: "Kararlar" + "." + this.KararBilgisi.kararId,
        dataekle: this.KararBilgisi,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Karar Silindi");
      this.kararlar = this.$store.getters.getKararlar;
      this.kararlist = Object.values(this.kararlar);
      this.kararlist.sort(function(b, a) {
        return a.kararId - b.kararId;
      });
      this.KararBilgisi = {};
    },

    duzenle(karar, hangisi) {
      this.KararBilgisi = karar;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.date1("duzenle");
      }
    },

    async kararekle() {
      const tarih = document.querySelector("#datepicker").value;
      if (
        this.KararBilgisi.kararAdi == "" ||
        this.KararBilgisi.kararAdi == undefined
      ) {
        this.$root.$refs.toast.toastgoster("hata", "Karar Adı Boş Olamaz");
      } else if (tarih == "" || tarih == undefined) {
        this.$root.$refs.toast.toastgoster("hata", "Karar Tarihi Boş Olamaz");
      } else {
        const yeniId = Date.now().toString();
        if (this.dosyalar.length > 0) {
          const dataekle = {
            kapak: "",
            dosyakontrol: true,
            dosyalar: this.dosyalar,
            col: "Kararlar",
            ref: "Kararlar" + "/" + yeniId,
            key: "Kararlar" + "." + yeniId,
            dataekle: {
              kararAdi: this.KararBilgisi.kararAdi,
              kararId: yeniId,
              kararTarihi: tarih,
              Dosyalar: [],
            },
          };
          this.bodykontrol = false;
          this.progreskontrol = true;
          await this.$store.dispatch("veriguncelledosyali", dataekle);
        } else {
          const dataekle = {
            col: "Kararlar",
            key: "Kararlar" + "." + yeniId,
            dataekle: {
              kararAdi: this.KararBilgisi.kararAdi,
              kararId: yeniId,
              kararTarihi: tarih,
              Dosyalar: [],
            },
          };
          this.bodykontrol = false;
          this.progreskontrol = true;
          await this.$store.dispatch("veriguncelle", dataekle);
        }
        this.kararlar = this.$store.getters.getKararlar;
        this.kararlist = Object.values(this.kararlar);
        this.kararlist.sort(function(b, a) {
          return a.kararId - b.kararId;
        });
        this.bodykontrol = true;
        this.progreskontrol = false;
        this.showYeniModal = false;
        this.dosyalar = [];
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Karar Başarılı Bir Şekilde Eklendi."
        );
        this.KararBilgisi = {};
      }
    },

    async guncelle() {
      var tarih = document.querySelector("#datepicker").value;
      if (
        this.KararBilgisi.kararAdi == "" ||
        this.KararBilgisi.kararAdi == undefined
      ) {
        this.$root.$refs.toast.toastgoster("hata", "Karar Adı Boş Olamaz");
      } else {
        if (tarih == "" || tarih == undefined) {
          console.log("tarih bos");
        } else {
          this.KararBilgisi.kararTarihi = tarih;
        }
        this.bodykontrol = false;
        this.progreskontrol = true;
        if (this.dosyalar.length > 0) {
          const dataekle = {
            kapak: "",
            dosyakontrol: true,
            dosyalar: this.dosyalar,
            col: "Kararlar",
            ref: "Kararlar" + "/" + this.KararBilgisi.kararId,
            key: "Kararlar" + "." + this.KararBilgisi.kararId,
            dataekle: this.KararBilgisi,
          };
          await this.$store.dispatch("veriguncelledosyali", dataekle);
          this.dosyalar = [];
        } else {
          const dataekle = {
            col: "Kararlar",
            key: "Kararlar" + "." + this.KararBilgisi.kararId,
            dataekle: this.KararBilgisi,
          };
          await this.$store.dispatch("veriguncelle", dataekle);
        }
        this.kararlar = this.$store.getters.getKararlar;
        this.kararlist = Object.values(this.kararlar);
        this.kararlist.sort(function(b, a) {
          return a.kararId - b.kararId;
        });
        this.bodykontrol = true;
        this.progreskontrol = false;
        this.showModal = false;
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Karar Başarılı Bir Şekilde Güncellendi."
        );
        this.KararBilgisi = {};
      }
    },
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    let datepickscr = document.createElement("script");
    datepickscr.setAttribute("src", "vendor/pickadate/picker.js");
    document.head.appendChild(datepickscr);
    let datepickscr2 = document.createElement("script");
    datepickscr2.setAttribute("src", "vendor/pickadate/picker.time.js");
    document.head.appendChild(datepickscr2);
    let datepickscr4 = document.createElement("script");
    datepickscr4.setAttribute("src", "vendor/pickadate/picker.date.js");
    document.head.appendChild(datepickscr4);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.secbtn {
  padding-top: 20px;
}
</style>
