<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      dosyaisimleri: [],
      audioisim1: String,
      audies: [],
      imagee:
        "https://st3.myideasoft.com/idea/cy/49/myassets/products/109/07-302-herby.jpg?revision=1548253070",
    };
  },
  created() {
    this.$root.$refs.AU = this;
  },
  methods: {
    compress(e) {
      // console.log(e);
      e.target.files.forEach((audio) => {
        if (e.target.files.length <= 1) {
          this.audioisim1 = audio.name;
          this.$emit("audioisim", this.audioisim1);
        } else {
          this.audioisim1 = `${e.target.files.length} Adet Dosya Seçildi`;
          this.$emit("audioisim", this.audioisim1);
        }

        // const reader = new FileReader();
        // reader.readAsDataURL(audio);
        // reader.onload = () => {
        //   this.audies.push(reader.result);
        // };

        this.audies = audio
        console.log(audio)

        this.$emit("sesler", this.audies);
      });

      // console.log(this.audies);

      // for (var file in e.target.files) {
      //   console.log(file.name);
      //   this.audioisim1 = file.name;
      //   this.$emit("audioisim", this.audioisim1);
      // }
    },
    //     getData(audioFile) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = error => reject(error);
    // }
  },
};
</script>