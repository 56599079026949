<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Menü" />
      <div class="content-body">
        <div class="container-fluid">
          <div class="row mb-5 align-items-center">
            <div class="col-lg-3 mb-4 mb-lg-0">
              <a
                class="
                  btn btn-primary
                  light
                  btn-lg btn-block
                  rounded
                  shadow
                  px-2
                "
                @click="date1('ekle')"
                >+ Yeni Menü Ekle</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table
                  id="example5"
                  class="table display mb-4 dataTablesCard fs-14"
                >
                  <thead>
                    <tr>
                      <th>Menü Adı</th>
                      <th>Oluşturulma Tarihi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(Menu, index) in Menulist" :key="index">
                      <td>
                        <div class="media align-items-center">
                          <img
                            class="
                              img-fluid
                              rounded
                              mr-3
                              d-none d-xl-inline-block
                            "
                            width="70"
                            :src="Menu.kapak"
                            alt="zeplinx"
                          />
                          <div class="media-body" style="padding-right: 20px">
                            <h4 class="text-black font-w600 mb-1 wspace-no">
                              {{ Menu.menuAdi.substring(0, 30) }}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td class="d-none d-lg-table-cell">
                        {{ Menu.menuTarihi }}
                      </td>
                      <td class="d-flex beyaz">
                        <a
                          @click="duzenle(Menu, 'duzenle')"
                          class="btn btn-primary btn-sm px-4"
                          >Düzenle</a
                        >
                        <a
                          @click="duzenle(Menu, 'sil')"
                          class="btn btn-danger btn-sm ml-2 px-4"
                          >Sil</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EKLE -->
    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Yeni Menü Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="progreskontrol">{{ progress }}</p>
                  <div
                    v-if="bodykontrol"
                    class="row"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <div class="col-xl-6 col-lg-6">
                      <div class="form-group">
                        <p class="mb-1">Menü Adı</p>
                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Menü Adı Giriniz"
                          v-model="MenuBilgisi.menuAdi"
                        />
                      </div>
                      <div class="form-group">
                        <p class="mb-1">Menü Açıklaması</p>
                        <VueEditor v-model="MenuBilgisi.menuAciklamasi" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <p>Kapak Resmi</p>
                      <profilresim :imageurl="MenuBilgisi.kapak" />
                      <kapaksec :dosyaismi="dsykapak" />
                      <imageresizekapak
                        @dosyaisim="dsykapak = $event"
                        @resim="MenuBilgisi.kapak = $event"
                      />

                      <tr v-for="(dosya, indxx) in dosyalar" :key="indxx">
                        <td class="d-flex">{{ dosya.name }}</td>
                        <td>
                          <a @click="dosyasil(indxx)" class="ml-4">
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.50195 5.99994H5.5156H21.6248"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <div class="secbtn">
                        <div class="col-xl-10 col-lg-10">
                          <div class="basic-form custom_file_input">
                            <div class="input-group mb-3">
                              <div class="custom-file">
                                <input
                                  placeholder="Resim(ler) Seçiniz."
                                  id="file"
                                  type="file"
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                  @change="dosyasecme"
                                  multiple="true"
                                  class="custom-file-input"
                                />
                                <label class="custom-file-label"
                                  >Resim(ler) Seçiniz.</label
                                >
                              </div>
                              <div class="input-group-append">
                                <span class="input-group-text">Yükle</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="right-body">
                        <div class="message">
                          <div class="son-images">
                            <div class="inside-img">
                              <img
                                v-for="(item, index) in gorunenler"
                                :key="index"
                                :src="item"
                                alt
                              />
                              <button
                                v-if="resimler.length > 5"
                                class="son-buton"
                              >
                                {{ resimler.length - 5 }} +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p class="mb-1">Menü Tarihi Seç</p>
                      <input
                        name="datepicker"
                        class="datepicker-default form-control"
                        :placeholder="MenuBilgisi.menuTarihi"
                        id="datepicker"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="Menuekle()"
                  >
                    EKLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- DÜZENLE -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Menü Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p class="mb-1">Yüklü Dosyalar</p>
                  <tr>
                    <th
                      v-for="(dosya, indxx) in MenuBilgisi.Dosyalar"
                      :key="indxx"
                    >
                      <a @click="dosyasil(indxx, 'yuklu')" class="ml-4">
                        <img :src="dosya.dosyaurl" height="60px" alt="" />
                      </a>
                    </th>
                  </tr>
                  <p v-if="progreskontrol">{{ progress }}</p>
                  <div
                    v-if="bodykontrol"
                    class="row"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <div class="col-xl-6 col-lg-6">
                      <div class="form-group">
                        <p class="mb-1">Menü Adı</p>
                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Menü Adı Giriniz"
                          v-model="MenuBilgisi.menuAdi"
                        />
                      </div>
                      <div class="form-group">
                        <p class="mb-1">Menü Açıklaması</p>
                        <VueEditor v-model="MenuBilgisi.menuAciklamasi" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <p>Kapak Resmi</p>

                      <profilresim :imageurl="MenuBilgisi.kapak" />

                      <kapaksec :dosyaismi="dsykapak" />
                      <imageresizekapak
                        @dosyaisim="dsykapak = $event"
                        @resim="MenuBilgisi.kapak = $event"
                      />

                      <!-- YENİ EKLEDİĞİM -->
                      <tr v-for="(dosya, indxx) in gorunenler" :key="indxx">
                        <td class="d-flex">{{ dosya.name }}</td>
                        <td>
                          <a @click="dosyasil(indxx)" class="ml-4">
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.50195 5.99994H5.5156H21.6248"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                                stroke="#A0A0A0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>

                      <!-- YENİ EKLEDİĞİM -->

                      <imageresize
                        @gorunen="gorunenler = $event"
                        @resimler="resimler = $event"
                      />
                      <cokludosyasec :dosyaismi="dosyaismi" />

                      <p class="mb-1">Menü Tarihi Seç</p>
                      <input
                        name="datepicker"
                        class="datepicker-default form-control"
                        :placeholder="MenuBilgisi.menuTarihi"
                        id="datepicker"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="Menuguncelle()"
                  >
                    GÜNCELLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- SİL -->
    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Menü Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ MenuBilgisi.MenuAdi }} isimli menüyü silmek istiyor
                    musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import imageresizekapak from "@/components/yardimcilar/imageresizekapak.vue";
import cokludosyasec from "@/components/yardimcilar/cokludosyasec.vue";
import kapaksec from "@/components/yardimcilar/kapaksec.vue";
import profilresim from "@/components/baskan/profilresim.vue";

export default {
  name: "Menuler",
  data() {
    return {
      dosyaismi: "Dosya Seç",
      dsykapak: "Kapak Resmi Seç",
      MenuBilgisi: {
        menuAdi: "",
        menuId: "",
        menuTarihi: "",
        menuAciklamasi: "",
        kapak: "",
        Dosyalar: [
          {
            dosyaAdi: "",
            dosyaId: "",
            dosyaTarihi: "",
            dosyaurl: "",
          },
        ],
      },
      showYeniModal: false,
      showModal: false,
      showAlert: false,
      kontrol: true,
      bodykontrol: true,
      progreskontrol: false,
      dosyalar: [],
      gorunenler: [],
      resimler: [],
      Menuler: {},
      Menulist: [],
    };
  },
  components: {
    NavSide,
    imageresizekapak,
    imageresize,
    cokludosyasec,
    kapaksec,
    profilresim,
  },

  async created() {
    await this.$store.dispatch("vericekme", "Menuler");
    this.Menuler = this.$store.getters.getMenuler;
    this.Menulist = Object.values(this.Menuler);
    this.Menulist.sort(function (b, a) {
      return a.menuId - b.menuId;
    });
  },

  methods: {
    date1(hangisi) {
      if (hangisi == "ekle") {
        this.MenuBilgisi = {};
        this.showYeniModal = true;
      } else {
        this.showModal = true;
      }
      let datepickscr5 = document.createElement("script");
      datepickscr5.setAttribute("src", "js/plugins-init/pickadate-init.js");
      document.head.appendChild(datepickscr5);
    },
    async dosyasil(indxx, hangisi) {
      if (hangisi == "yuklu") {
        var c = confirm("İçerik silinsin mi?")
        if (c == true) {
          const dosya = this.MenuBilgisi.Dosyalar[indxx];
          const url = dosya.dosyaurl;

          const dataekle = {
            url: url,
            col: "Menuler",
            key: "Menuler" + "." + this.MenuBilgisi.menuId,
            dataekle: this.MenuBilgisi,
          };
          await this.$store.dispatch("veridosyasilme", dataekle);
          this.MenuBilgisi.Dosyalar.splice(indxx, 1);
          await this.$store.dispatch("veriguncelle", dataekle);
        }

      } else {
        console.log(this.dosyalar);
        const test1 = Array.from(this.dosyalar);
        test1.splice(indxx, 1);
        this.dosyalar = test1;
      }
    },
    dosyasecme(e) {
      const files = e.target.files;
      this.dosyalar = files;
      console.log(this.dosyalar);
    },
    async sil() {
      const veribilgiler = {
        kapak: this.MenuBilgisi.kapak,
        dosyakontrol: true,
        col: "Menuler",
        key: "Menuler" + "." + this.MenuBilgisi.menuId,
        dataekle: this.MenuBilgisi,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Menü Silindi");
      this.Menuler = this.$store.getters.getMenuler;
      this.Menulist = Object.values(this.Menuler);
      this.Menulist.sort(function (b, a) {
        return a.menuId - b.menuId;
      });
      this.MenuBilgisi = {};
    },

    duzenle(menu, hangisi) {
      this.MenuBilgisi = menu;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.date1("duzenle");
      }
      this.dsykapak == "Kapak Resmi Seç";
    },

    async Menuekle() {
      const tarih = document.querySelector("#datepicker").value;
      if (
        this.MenuBilgisi.menuAdi == "" ||
        this.MenuBilgisi.menuAdi == undefined
      ) {
        this.$root.$refs.toast.toastgoster("hata", "Menü Adı Boş Olamaz");
      } else if (tarih == "" || tarih == undefined) {
        this.$root.$refs.toast.toastgoster("hata", "Menü Tarihi Boş Olamaz");
      } else {
        const yeniId = Date.now().toString();
        if (this.dosyalar.length > 0 || this.dsykapak != "Kapak Resmi Seç") {
          const dataekle = {
            kapak: this.MenuBilgisi.kapak,
            dosyakontrol: true,
            dosyalar: this.dosyalar,
            col: "Menuler",
            ref: "Menuler" + "/" + yeniId,
            key: "Menuler" + "." + yeniId,
            dataekle: {
              menuAdi: this.MenuBilgisi.menuAdi,
              menuId: yeniId,
              menuTarihi: tarih,
              menuAciklamasi: this.MenuBilgisi.menuAciklamasi,
              Dosyalar: [],
            },
          };

          this.bodykontrol = false;
          this.progreskontrol = true;
          await this.$store.dispatch("veriguncelledosyali", dataekle);
        } else {
          console.log("dosya yok");
          const dataekle = {
            col: "Menuler",
            key: "Menuler" + "." + yeniId,
            dataekle: {
              menuAdi: this.MenuBilgisi.menuAdi,
              menuId: yeniId,
              menuTarihi: tarih,
              menuAciklamasi: this.MenuBilgisi.menuAciklamasi,
              Dosyalar: [],
            },
          };
          this.bodykontrol = false;
          this.progreskontrol = true;
          await this.$store.dispatch("veriguncelle", dataekle);
        }
        this.Menuler = this.$store.getters.getMenuler;
        this.Menulist = Object.values(this.Menuler);
        this.Menulist.sort(function (b, a) {
          return a.menuId - b.menuId;
        });
        this.bodykontrol = true;
        this.progreskontrol = false;
        this.showYeniModal = false;
        this.dosyalar = [];
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Menü Başarılı Bir Şekilde Eklendi."
        );
        this.MenuBilgisi = {};
      }
    },

    async Menuguncelle() {
      var tarih = document.querySelector("#datepicker").value;
      if (
        this.MenuBilgisi.menuAdi == "" ||
        this.MenuBilgisi.menuAdi == undefined
      ) {
        this.$root.$refs.toast.toastgoster("hata", "Menu Adı Boş Olamaz");
      } else {
        if (tarih == "" || tarih == undefined) {
          console.log("tarih bos");
          tarih = this.MenuBilgisi.menuTarihi;
        } else {
          this.MenuBilgisi.menuTarihi = tarih;
        }
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Yükleniyor... Lütfen Sabırlı Olunuz..."
        );
        if (this.resimler.length > 0) {
          if (this.dsykapak == "Kapak Resmi Seç") {
            const dataekle = {
              kapak: "ekli",
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "Menuler",
              ref: "Menuler" + "/" + this.MenuBilgisi.menuId,
              key: "Menuler" + "." + this.MenuBilgisi.menuId,
              dataekle: {
                kapak: this.MenuBilgisi.kapak,
                menuAdi: this.MenuBilgisi.menuAdi,
                menuId: this.MenuBilgisi.menuId,
                menuTarihi: tarih,
                menuAciklamasi: this.MenuBilgisi.menuAciklamasi,
                Dosyalar: this.MenuBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
          } else {
            const dataekle = {
              kapak: this.MenuBilgisi.kapak,
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "Menuler",
              ref: "Menuler" + "/" + this.MenuBilgisi.menuId,
              key: "Menuler" + "." + this.MenuBilgisi.menuId,
              dataekle: {
                kapak: this.MenuBilgisi.kapak,
                menuAdi: this.MenuBilgisi.menuAdi,
                menuId: this.MenuBilgisi.menuId,
                menuTarihi: tarih,
                menuAciklamasi: this.MenuBilgisi.menuAciklamasi,
                Dosyalar: this.MenuBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
          }
        } else {
          if (this.dsykapak != "Kapak Resmi Seç") {
            const dataekle = {
              kapak: this.MenuBilgisi.kapak,
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "Menuler",
              ref: "Menuler" + "/" + this.MenuBilgisi.menuId,
              key: "Menuler" + "." + this.MenuBilgisi.menuId,
              dataekle: {
                kapak: this.MenuBilgisi.kapak,
                menuAdi: this.MenuBilgisi.menuAdi,
                menuId: this.MenuBilgisi.menuId,
                menuTarihi: tarih,
                menuAciklamasi: this.MenuBilgisi.menuAciklamasi,
                Dosyalar: this.MenuBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
          } else {
            const dataekle = {
              dosyakontrol: false,
              col: "Menuler",
              key: "Menuler" + "." + this.MenuBilgisi.menuId,
              dataekle: {
                kapak: this.MenuBilgisi.kapak,
                menuAdi: this.MenuBilgisi.menuAdi,
                menuId: this.MenuBilgisi.menuId,
                menuTarihi: tarih,
                menuAciklamasi: this.MenuBilgisi.menuAciklamasi,
                Dosyalar: this.MenuBilgisi.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelle", dataekle);
          }
        }
        this.menuler = this.$store.getters.getMenuler;
        this.menulist = Object.values(this.menuler);
        this.menulist.sort(function (b, a) {
          return a.menuId - b.menuId;
        });
        this.bodykont = true;
        this.prekontrol = false;
        this.showModal = false;
        this.resimler = [];
        this.gorunenler = [];
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Menu Başarılı Bir Şekilde Eklendi."
        );
        this.MenuBilgisi = {};
        this.resimler = [];
        this.gorunenler = [];
      }
    },
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    let datepickscr = document.createElement("script");
    datepickscr.setAttribute("src", "vendor/pickadate/picker.js");
    document.head.appendChild(datepickscr);
    let datepickscr2 = document.createElement("script");
    datepickscr2.setAttribute("src", "vendor/pickadate/picker.time.js");
    document.head.appendChild(datepickscr2);
    let datepickscr4 = document.createElement("script");
    datepickscr4.setAttribute("src", "vendor/pickadate/picker.date.js");
    document.head.appendChild(datepickscr4);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.secbtn {
  padding-top: 20px;
}
</style>
