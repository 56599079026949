<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>

    <div id="main-wrapper">
      <NavSide sayfa="Meclis Üyeleri" />
      <div class="content-body">
        <!-- row -->
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="row mb-5 align-items-center">
                <div class="col-lg-3 mb-4 mb-lg-0">
                  <a
                    @click="ekle1"
                    class="
                      btn btn-primary
                      light
                      btn-lg btn-block
                      rounded
                      shadow
                      px-2
                    "
                    >+ Meclis Üyesi Ekle</a
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="tab-content">
                <div id="navpills-1" class="tab-pane active">
                  <div class="table-responsive table-hover fs-14">
                    <table
                      class="table display mb-4 dataTablesCard fs-14"
                      id="example5"
                    >
                      <thead>
                        <tr>
                          <th class="d-none d-lg-table-cell">Meclis Üyesi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="uye in uyelist" :key="uye.uyeId">
                          <td>
                            <div class="media align-items-center">
                              <img
                                class="
                                  img-fluid
                                  rounded
                                  mr-3
                                  d-none d-xl-inline-block
                                "
                                width="70"
                                :src="uye.kapak"
                                alt="zeplinx"
                              />
                              <div class="media-body">
                                <h4 class="text-black font-w600 mb-1 wspace-no">
                                  {{ uye.uyeAdi }}
                                </h4>
                                <span>{{ uye.uyeId }}</span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div class="d-flex beyaz">
                              <a
                                @click="duzenle(uye, 'duzenle')"
                                class="btn btn-primary btn-sm px-4"
                                >Düzenle</a
                              >
                              <a
                                @click="duzenle(uye, 'sil')"
                                class="btn btn-danger btn-sm ml-2 px-4"
                                >Sil</a
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Meclis Üyesi Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    v-if="bodykont"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <profilresim :imageurl="uyebilgiler.kapak" />
                    <imageresize
                      @dosyaisim="dsyisim = $event"
                      @resim="uyebilgiler.kapak = $event"
                    />
                    <dosyasecbtn :dosyaismi="dsyisim" />
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Meclis Üyesi Adı"
                        v-model="uyebilgiler.uyeAdi"
                      />
                    </div>
                    <div class="basic-form">
                      <form>
                        <div class="form-group">
                          <input
                            class="form-control"
                            placeholder="Meclis Üyesi Partisi"
                            rows="3"
                            id="comment"
                            v-model="uyebilgiler.uyePartisi"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="uyeguncelle()"
                  >
                    GÜNCELLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Meclis Üyesi Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    v-if="bodykont"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <profilresim :imageurl="uyebilgiler.kapak" />
                    <imageresize
                      @dosyaisim="dsyisim = $event"
                      @resim="uyebilgiler.kapak = $event"
                    />
                    <dosyasecbtn :dosyaismi="dsyisim" />
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Meclis Üyesi Adı"
                        v-model="uyebilgiler.uyeAdi"
                      />
                    </div>
                    <div class="basic-form">
                      <form>
                        <div class="form-group">
                          <input
                            class="form-control"
                            placeholder="Meclis Üyesi Partisi"
                            rows="3"
                            id="comment"
                            v-model="uyebilgiler.uyePartisi"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="uyeekle()"
                  >
                    EKLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Meclis Üyesi Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ uyebilgiler.uyeAdi }} isimli meclis üyesini silmek
                    istiyor musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import profilresim from "@/components/baskan/profilresim.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import dosyasecbtn from "@/components/yardimcilar/dosyasecbtn.vue";
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "Meclisuyeler",
  data() {
    return {
      uyebilgiler: {
        uyeAdi: "",
        uyePartisi: "",
        uyeId: "",
        kapak: "",
      },
      prekontrol: false,
      bodykont: true,
      dsyisim: "Resim Seç",
      showYeniModal: false,
      showModal: false,
      showAlert: false,
      kontrol: true,
      uyelist: [],
      uyeler: {},
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Uyeler");
    this.uyeler = this.$store.getters.getUyeler;
    this.uyelist = Object.values(this.uyeler);
    this.uyelist.sort(function (b, a) {
      return a.uyeId - b.uyeId;
    });
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  components: {
    NavSide,
    profilresim,
    imageresize,
    dosyasecbtn,
  },
  methods: {
    ekle1() {
      this.showYeniModal = true;
      this.uyebilgiler = {};
    },
    async sil() {
      const veribilgiler = {
        dosyakontrol: false,
        kapak: this.uyebilgiler.kapak,
        col: "Uyeler",
        key: "Uyeler" + "." + this.uyebilgiler.uyeId,
        dataekle: this.uyebilgiler,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Meclis Üyesi Silindi");
      this.uyeler = this.$store.getters.getUyeler;
      this.uyelist = Object.values(this.uyeler);
      this.uyelist.sort(function (b, a) {
        return a.uyeId - b.uyeId;
      });
    },
    async uyeguncelle() {
      this.bodykont = false;
      this.prekontrol = true;

      if (this.dsyisim != "Resim Seç") {
        const dataekle = {
          dosyakontrol: false,
          dosyalar: [this.uyebilgiler.kapak],
          kapak: this.uyebilgiler.kapak,
          col: "Uyeler",
          key: "Uyeler" + "." + this.uyebilgiler.uyeId,
          ref: "Uyeler" + "/" + this.uyebilgiler.uyeId,
          dataekle: this.uyebilgiler,
        };
        await this.$store.dispatch("veriguncelledosyali", dataekle);
      } else {
        const dataekle = {
          col: "Uyeler",
          key: "Uyeler" + "." + this.uyebilgiler.uyeId,
          dataekle: this.uyebilgiler,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
      }
      this.showModal = false;
      this.prekontrol = false;
      this.bodykont = true;
      this.$root.$refs.toast.toastgoster("basari", "Güncelleme Başarılı");
      this.dsyisim = "Resim Seç";
      this.uyeler = this.$store.getters.getUyeler;
      this.uyelist = Object.values(this.uyeler);
      this.uyelist.sort(function (b, a) {
        return a.uyeId - b.uyeId;
      });
      this.uyebilgiler = {};
    },

    async uyeekle() {
      const yeniuyeId = Date.now().toString();
      if (this.dsyisim != "Resim Seç") {
        this.bodykont = false;
        this.prekontrol = true;
        const yenidata = this.uyebilgiler;
        yenidata["uyeId"] = yeniuyeId;
        const dataekle = {
          dosyakontrol: false,
          dosyalar: [this.uyebilgiler.kapak],
          kapak: this.uyebilgiler.kapak,
          col: "Uyeler",
          key: "Uyeler" + "." + yeniuyeId,
          ref: "Uyeler" + "/" + yeniuyeId,
          dataekle: this.uyebilgiler,
        };
        await this.$store.dispatch("veriguncelledosyali", dataekle);
        this.uyeler = this.$store.getters.getUyeler;
        this.uyelist = Object.values(this.uyeler);
        this.uyelist.sort(function (b, a) {
          return a.uyeId - b.uyeId;
        });
        this.uyebilgiler = {};
        this.bodykont = true;
        this.prekontrol = false;
        this.showYeniModal = false;
        this.dsyisim = "Resim Seç";
      } else {
        this.$root.$refs.toast.toastgoster("hata", "Lüten Resim Seçiniz.");
      }
    },

    duzenle(uye, hangisi) {
      this.uyebilgiler = uye;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.showModal = true;
      }
    },
  },

  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>