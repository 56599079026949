<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>

    <div id="main-wrapper">
      <NavSide sayfa="Encumenlar" />
      <div class="content-body">
        <!-- row -->
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="row mb-5 align-items-center">
                <div class="col-lg-3 mb-4 mb-lg-0">
                  <a
                    @click="showYeniModal = true"
                    class="
                      btn btn-primary
                      light
                      btn-lg btn-block
                      rounded
                      shadow
                      px-2
                    "
                    >+ Encumen Ekle</a
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="tab-content">
                <div id="navpills-1" class="tab-pane active">
                  <div class="table-responsive table-hover fs-14">
                    <table
                      class="table display mb-4 dataTablesCard fs-14"
                      id="example5"
                    >
                      <thead>
                        <tr>
                          <th class="d-none d-lg-table-cell">Encumen</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="encumen in encumenlist"
                          :key="encumen.encumenId"
                        >
                          <td>
                            <div class="media align-items-center">
                              <img
                                class="
                                  img-fluid
                                  rounded
                                  mr-3
                                  d-none d-xl-inline-block
                                "
                                width="70"
                                :src="encumen.kapak"
                                alt="zeplinx"
                              />
                              <div class="media-body">
                                <h4 class="text-black font-w600 mb-1 wspace-no">
                                  {{ encumen.encumenAdi }}
                                </h4>
                                <span>{{ encumen.mahalleAdi }}</span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div class="d-flex beyaz">
                              <a
                                @click="duzenle(encumen, 'duzenle')"
                                class="btn btn-primary btn-sm px-4"
                                >Düzenle</a
                              >
                              <a
                                @click="duzenle(encumen, 'sil')"
                                class="btn btn-danger btn-sm ml-2 px-4"
                                >Sil</a
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Encumen Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    v-if="bodykont"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <profilresim :imageurl="EncumenBilgisi.kapak" />
                    <imageresize
                      @dosyaisim="dsyisim = $event"
                      @resim="EncumenBilgisi.kapak = $event"
                    />
                    <dosyasecbtn :dosyaismi="dsyisim" />
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Encumen Adı"
                        v-model="EncumenBilgisi.encumenAdi"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Encumen Telefon No"
                        v-model="EncumenBilgisi.encumenTel"
                      />
                    </div>
                    <!-- <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Parti Grubu"
                        v-model="EncumenBilgisi.mahalleAdi"
                      />
                    </div> -->
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Encümen Bilgi"
                        v-model="EncumenBilgisi.encumenlikAdresi"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="encumenguncelle()"
                  >
                    GÜNCELLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Encumen Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{ progress }} yükleniyor</p>
                  <div
                    v-if="bodykont"
                    style="overflow-y: scroll; height: 400px"
                  >
                    <profilresim :imageurl="EncumenBilgisi.kapak" />
                    <imageresize
                      @dosyaisim="dsyisim = $event"
                      @resim="EncumenBilgisi.kapak = $event"
                    />
                    <dosyasecbtn :dosyaismi="dsyisim" />
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Encumen Adı"
                        v-model="EncumenBilgisi.encumenAdi"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Encumen Telefon No"
                        v-model="EncumenBilgisi.encumenTel"
                      />
                    </div>
                    <!-- <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Parti Grubu"
                        v-model="EncumenBilgisi.mahalleAdi"
                      />
                    </div> -->
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Encumen Bilgi"
                        v-model="EncumenBilgisi.encumenlikAdresi"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="encumenekle()"
                  >
                    EKLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Meclis Üyesi Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ EncumenBilgisi.encumenAdi }} isimli encümeni silmek
                    istiyor musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import profilresim from "@/components/baskan/profilresim.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import dosyasecbtn from "@/components/yardimcilar/dosyasecbtn.vue";
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "Encumenlar",
  data() {
    return {
      prekontrol: false,
      bodykont: true,
      EncumenBilgisi: {
        mahalleAdi: "",
        encumenAdi: "",
        kapak: "",
        encumenId: "",
        encumenTel: "",
        encumenlikAdresi: "",
      },
      dsyisim: "Resim Seç",
      showYeniModal: false,
      showModal: false,
      showAlert: false,
      kontrol: true,
      encumenlist: [],
      encumenlar: {},
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Encumenlar");
    this.encumenlar = this.$store.getters.getEncumenlar;
    console.log("mm" + this.encumenlar);
    this.encumenlist = Object.values(this.encumenlar);
    console.log("mt" + this.encumenlist);
    this.encumenlist.sort(function (b, a) {
      return a.encumenId - b.encumenId;
    });
    console.log(this.encumenlist);
  },
  components: {
    NavSide,
    profilresim,
    dosyasecbtn,
    imageresize,
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },

  methods: {
    async sil() {
      const veribilgiler = {
        dosyakontrol: false,
        kapak: this.EncumenBilgisi.kapak,
        col: "Encumenlar",
        key: "Encumenlar" + "." + this.EncumenBilgisi.encumenId,
        dataekle: this.EncumenBilgisi,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Encumen Silindi");
      this.encumenlar = this.$store.getters.getEncumenlar;
      this.encumenlist = Object.values(this.encumenlar);
      this.encumenlist.sort(function (b, a) {
        return a.encumenId - b.encumenId;
      });
      this.EncumenBilgisi = {};
    },
    duzenle(encumen, hangisi) {
      this.EncumenBilgisi = encumen;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.showModal = true;
      }
    },

    async encumenekle() {
      const yeniencumenid = Date.now().toString();
      if (this.dsyisim != "Resim Seç") {
        const yenidata = this.EncumenBilgisi;
        yenidata["encumenId"] = yeniencumenid;
        const dataekle = {
          dosyakontrol: false,
          dosyalar: [this.EncumenBilgisi.kapak],
          kapak: this.EncumenBilgisi.kapak,
          col: "Encumenlar",
          key: "Encumenlar" + "." + yeniencumenid,
          ref: "Encumenlar" + "/" + yeniencumenid,
          dataekle: this.EncumenBilgisi,
        };
        this.bodykont = false;
        this.prekontrol = true;
        await this.$store.dispatch("veriguncelledosyali", dataekle);
        this.encumenlar = this.$store.getters.getEncumenlar;
        this.encumenlist = Object.values(this.encumenlar);
        this.encumenlist.sort(function (b, a) {
          return a.encumenId - b.encumenId;
        });
        this.showYeniModal = false;
        this.prekontrol = false;
        this.bodykont = true;
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Ekleme Fotoğraflı Başarılı"
        );
      } else {
        this.$root.$refs.toast.toastgoster("hata", "Lüten Resim Seçiniz.");
      }
      (this.EncumenBilgisi = {}), (this.dsyisim = "Resim Seç");
    },

    async encumenguncelle() {
      if (this.dsyisim != "Resim Seç") {
        const dataekle = {
          dosyakontrol: false,
          dosyalar: [this.EncumenBilgisi.kapak],
          kapak: this.EncumenBilgisi.kapak,
          col: "Encumenlar",
          key: "Encumenlar" + "." + this.EncumenBilgisi.encumenId,
          ref: "Encumenlar" + "/" + this.EncumenBilgisi.encumenId,
          dataekle: this.EncumenBilgisi,
        };
        this.bodykont = false;
        this.prekontrol = true;
        await this.$store.dispatch("veriguncelledosyali", dataekle);
      } else {
        const dataekle = {
          col: "Encumenlar",
          key: "Encumenlar" + "." + this.EncumenBilgisi.encumenId,
          dataekle: this.EncumenBilgisi,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
      }
      this.showModal = false;
      this.prekontrol = false;
      this.bodykont = true;
      this.$root.$refs.toast.toastgoster(
        "basari",
        "Güncelleme Fotoğraflı Başarılı"
      );
      this.encumenlar = this.$store.getters.getEncumenlar;
      this.encumenlist = Object.values(this.encumenlar);
      this.encumenlist.sort(function (b, a) {
        return a.encumenId - b.encumenId;
      });
      (this.EncumenBilgisi = {}), (this.dsyisim = "Resim Seç");
    },
  },

  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>