<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Destekler" />
      <div class="content-body">
        <link href="vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
        <link
          href="vendor/datatables/css/jquery.dataTables.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css"
          rel="stylesheet"
        />
        <link href="css/style.css" rel="stylesheet" />
        <div class="container-fluid">
          <div class="row mb-5 align-items-center">
            <div class="col-lg-3 mb-4 mb-lg-0">
              <a
                class="btn btn-primary light btn-lg btn-block rounded shadow px-2"
                @click="showYeniModal = true"
                >+ Destek Bileti Oluştur</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table
                  id="example5"
                  class="table display mb-4 dataTablesCard fs-14"
                >
                  <thead>
                    <tr>
                      <th>Destek Adı</th>
                      <th>Destek Id'si</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="destek in desteklist" :key="destek.destekId">
                      <td>{{ destek.destekadi }}</td>
                      <td>{{ destek.destekId }}</td>
                      <td>{{ destek.destekDurumText }}</td>
                      <td class="d-flex">
                        <a @click="duzenle(destek, 'duzenle')">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.2557 2.99994C17.5201 2.73729 17.8341 2.52895 18.1796 2.38681C18.5251 2.24467 18.8954 2.17151 19.2694 2.17151C19.6433 2.17151 20.0136 2.24467 20.3591 2.38681C20.7046 2.52895 21.0186 2.73729 21.283 2.99994C21.5474 3.26258 21.7572 3.57438 21.9003 3.91754C22.0434 4.2607 22.1171 4.6285 22.1171 4.99994C22.1171 5.37137 22.0434 5.73917 21.9003 6.08233C21.7572 6.42549 21.5474 6.73729 21.283 6.99994L7.69086 20.4999L2.15332 21.9999L3.66356 16.4999L17.2557 2.99994Z"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                        <a @click="duzenle(destek, 'sil')" class="ml-4">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.50195 5.99994H5.5156H21.6248"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Yeni Destek Ekle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showYeniModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Destek Adı"
                      v-model="DestekBilgileri.destekadi"
                    />
                  </div>
                  <div class="basic-form">
                    <form>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          placeholder="Destek Açıklaması"
                          rows="12"
                          id="comment"
                          v-model="DestekBilgileri.destekaciklamasi"
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="destekekle()"
                  >
                    EKLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Destek Düzenle</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-default"
                      placeholder="Destek Adı"
                      v-model="DestekBilgileri.destekadi"
                    />
                  </div>
                  <div class="basic-form">
                    <form>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          rows="12"
                          id="comment"
                          v-model="DestekBilgileri.destekaciklamasi"
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="destekguncelle()"
                  >
                    GÜNCELLE
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Destek Silme</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ DestekBilgileri.destekadi }} isimli desteki silmek
                    istiyor musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "Destekler",
  data() {
    return {
      DestekBilgileri: {
        destekId: "",
        destekaciklamasi: "",
        destekadi: "",
        domainName: "",
        destekDurum: 0,
      },
      showYeniModal: false,
      showModal: false,
      showAlert: false,
      desteklist: [],
      destekler: {},
      kontrol: false,
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Genel");
    this.DestekBilgileri.domainName = this.$store.state.BelediyeGenelBilgiler.belediyeadi
    await this.$store.dispatch("vericekme", "Destekler");
    this.destekler = this.$store.getters.getDestekler;
    this.desteklist = Object.values(this.destekler);
    this.desteklist.forEach((el) => {
      switch (el.destekDurum) {
        case 0:
          el.destekDurumText = "Beklemede"
          break;
        case 1:
          el.destekDurumText = "İnceleniyor"
          break;
        case 2:
          el.destekDurumText = "Çözüldü"
          break;
      
        default:
          break;
      }
    })
    this.desteklist.sort(function (b, a) {
      return a.destekId - b.destekId;
    });
  },
  components: {
    NavSide,
  },
  methods: {
    async sil() {
      const veribilgiler = {
        dosyakontrol: false,
        kapak: "",
        col: "Destekler",
        key: "Destekler" + "." + this.DestekBilgileri.destekId,
        dataekle: this.DestekBilgileri,
      };
      await this.$store.dispatch("verisilme", veribilgiler);
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Destek Silindi");
      this.destekler = this.$store.getters.getDestekler;
      this.desteklist = Object.values(this.destekler);
      this.desteklist.sort(function (b, a) {
        return a.destekId - b.destekId;
      });
      this.DestekBilgileri = {};
    },
    duzenle(destek, hangisi) {
      this.DestekBilgileri = destek;
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.showModal = true;
      }
    },
    async destekguncelle() {
      if (this.DestekBilgileri.destekadi == "") {
        this.$root.$refs.toast.toastgoster("hata", "Destek Adı Boş Olamaz");
      } else if (this.DestekBilgileri.destekaciklamasi == "") {
        this.$root.$refs.toast.toastgoster(
          "hata",
          "Destek Açıklaması Boş Olamaz"
        );
      } else {
        const dataekle = {
          col: "Destekler",
          key: "Destekler" + "." + this.DestekBilgileri.destekId,
          dataekle: this.DestekBilgileri,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
        this.destekler = this.$store.getters.getDestekler;
        this.desteklist = Object.values(this.destekler);
        this.desteklist.sort(function (b, a) {
          return a.destekId - b.destekId;
        });
        this.DestekBilgileri = {};
        this.showModal = false;
        this.$root.$refs.toast.toastgoster("basari", "Destek Güncellendi");
      }
    },
    async destekekle() {
      if (this.DestekBilgileri.destekadi == "") {
        this.$root.$refs.toast.toastgoster("hata", "Destek Adı Boş Olamaz");
      } else if (this.DestekBilgileri.destekaciklamasi == "") {
        this.$root.$refs.toast.toastgoster(
          "hata",
          "Destek Açıklaması Boş Olamaz"
        );
      } else {
        const yeniId = Date.now().toString();
        const dat1 = this.DestekBilgileri;
        dat1["destekId"] = yeniId;
        const dataekle = {
          col: "Destekler",
          key: "Destekler" + "." + this.DestekBilgileri.destekId,
          dataekle: this.DestekBilgileri,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
        this.destekler = this.$store.getters.getDestekler;
        this.desteklist = Object.values(this.destekler);
        this.desteklist.sort(function (b, a) {
          return a.destekId - b.destekId;
        });
        this.DestekBilgileri = {};
        this.showYeniModal = false;
        this.$root.$refs.toast.toastgoster(
          "basari",
          "Destek Başarılı Bir Şekilde Eklendi."
        );
      }
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>