<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Tarihçe" />
      <div class="content-body">
        <div class="container-fluid">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">İlçe Tarihçe</h4>
              </div>
              <div class="card-body">
                <div class="basic-form">
                  <form>
                    <div class="form-group">
                      <VueEditor v-model="IlceBilgiler.tarihce" />
                    </div>
                  </form>
                </div>
                <button type="button" class="btn btn-outline-primary" @click="tarihceekle">EKLE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "Tarihce",
  data() {
    return {
      IlceBilgiler: {
        tarihce: "",
        Dosyalar: []
      },
      kontrol: true
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Ilce");
    this.IlceBilgiler = this.$store.getters.getIlce;
  },
  components: {
    NavSide
  },
  methods: {
    async tarihceekle() {
      if (this.IlceBilgiler.tarihce == "") {
        this.$root.$refs.toast.toastgoster("hata", "Tarihçe Giriniz !!");
      } else {
        const dataekle = {
          col: "Ilce",
          key: "Ilce",
          dataekle: this.IlceBilgiler
        };
        await this.$store.dispatch("veriguncelle", dataekle);
        this.$root.$refs.toast.toastgoster("basari", "Tarihçe Güncellendi");
      }
    }
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  }
};
</script>