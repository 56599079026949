<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Başkan Galeri" />
      <div class="content-body">
        <div class="container-fluid">
          <div class="col-xl-12">
            <div class="row mb-5 align-items-center">
              <div class="col-lg-3 mb-4 mb-lg-0">
                <a
                  @click="showYeniModal=true"
                  class="btn btn-primary light btn-lg btn-block rounded shadow px-2"
                >+ Resim Ekle</a>
              </div>
            </div>
          </div>
          <!-- Grid row -->
          <!-- Page Content -->
          <div class="container">
            <hr class="mt-2 mb-5" />

            <div class="row text-center text-lg-left">
              <div
                v-for="(item,index) in BaskanBilgileri.Dosyalar"
                :key="index"
                class="col-lg-3 col-md-4 col-6"
              >
                <a class="d-block mb-4 h-100">
                  <img @click="indxx=index;showAlert=true;" class="img-fluid img-thumbnail" :src="item.dosyaurl" alt />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showYeniModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Başkan Galeri Resim Ekle</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="showYeniModal = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="prekontrol">{{progress}} yükleniyor</p>
                  <div v-if="bodykont" style="overflow-y: scroll; height:400px;">
                    <imageresize @gorunen="gorunenler=$event" @resimler="resimler=$event" />
                    <cokludosyasec :dosyaismi="dsyisim" />
                    <div class="right-body">
                      <div class="message">
                        <div class="son-images">
                          <div class="inside-img">
                            <img v-for="(item,index) in gorunenler" :key="index" :src="item" alt />
                            <button
                              v-if="resimler.length>5"
                              class="son-buton"
                            >{{resimler.length-5}} +</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="resimekle()">EKLE</button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showYeniModal = false"
                  >KAPAT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Resim Silme</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="showAlert = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Bu resmi silmek istiyor musunuz ?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="dosyasil('yuklu')">Evet SiL</button>
                  <button type="button" class="btn btn-secondary" @click="showAlert = false">VAZGEÇ</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>    
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import cokludosyasec from "@/components/yardimcilar/cokludosyasec.vue";
export default {
  name: "Baskangaleri",
  components: {
    NavSide,
    imageresize,
    cokludosyasec
  },
  data() {
    return {
      progreskontrol: false,
      BaskanBilgileri: {},
      gorunenler: [],
      resimler: [],
      dsyisim: "Resim Seç",
      prekontrol: false,
      bodykont: true,
      showYeniModal: false,
      showAlert:false,
      kontrol: true,
      indxx:0,
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Baskan");
    this.BaskanBilgileri = this.$store.getters.getBaskan;
    this.BaskanBilgileri.Dosyalar.reverse();
  },
  methods: {
    async dosyasil(hangisi) {
      if (hangisi == "yuklu") {
        const dosya = this.BaskanBilgileri.Dosyalar[this.indxx];
        const url = dosya.dosyaurl;

        const dataekle = {
          url: url,
          col: "Baskan",
          key: "Baskan",
          dataekle: this.BaskanBilgileri
        };
        await this.$store.dispatch("veridosyasilme", dataekle);
        this.BaskanBilgileri.Dosyalar.splice(this.indxx, 1);
        await this.$store.dispatch("veriguncelle", dataekle);
      } else {
        console.log(this.dosyalar);
        const test1 = Array.from(this.resimler);
        test1.splice(this.indxx, 1);
        this.resimler = test1;
      }
      this.showAlert=false;
      await this.$root.$refs.toast.toastgoster("basari","Resim Silindi");
       this.resimler=[];
        this.gorunenler=[];
    },
        async resimekle() {
      if (this.resimler.length < 1) {
        this.$root.$refs.toast.toastgoster("hata", "Resim Seçiniz!!");
      } else {
        const dataekle = {
          kapak: "ekli",
          dosyakontrol: true,
          dosyalar: this.resimler,
          col: "Baskan",
          ref: "Baskan" + "/",
          key: "Baskan",
          dataekle: this.BaskanBilgileri
        };
        this.prekontrol=true;
        this.bodykont=false;
        await this.$store.dispatch("veriguncelledosyali", dataekle);
        this.prekontrol=false;
        this.bodykont=true;
        this.showYeniModal=false;
        this.$root.$refs.toast.toastgoster("basari", "Mesaj Güncellendi");
        this.resimler=[];
        this.gorunenler=[];
      }
    }
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  mounted() {
    let externalScript3 = document.createElement("script");
    externalScript3.setAttribute("src", "vendor/dropzone/dist/dropzone.js");
    document.head.appendChild(externalScript3);
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
      document.getElementById("preloader").style.opacity = "0";
    }, 100);
  }
};
</script>



<style>
.right-side {
  background-color: #f2f3f7;
  width: 100%;
  padding: 8px 30px;
  display: flex;
  flex-direction: column;
}
.right-body {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.message {
  margin: 20px 10px;
  flex: 1;
  background-color: white;
  padding: 25px;
  overflow: auto;
}

.son-images {
  display: flex;
  align-items: center;

  width: 100%;
}
.inside-img > img {
  width: 100px;
  height: 100px;
  padding-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
}
.inside-img > img:hover {
  transform: scale(0.95);
}
.son-buton {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px;
  margin-top: 17px;
  background-color: #f5e9f9;
  color: #b79ed8;
  font-weight: 500;
  font-size: 20px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>