<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="KVKK Metni" />
      <div class="content-body">
        <div class="container-fluid">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">KVKK</h4>
              </div>
              <div class="card-body">
                <div class="secbtn">
                        <div class="col-xl-10 col-lg-10">
                          <div class="basic-form custom_file_input">
                            <div class="input-group mb-3">
                              <div class="custom-file">
                                <input
                                  placeholder="Resim(ler) Seçiniz."
                                  id="file"
                                  type="file"
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                  @change="dosyasecme"
                                  multiple="true"
                                  class="custom-file-input"
                                />
                                <label class="custom-file-label"
                                  >Resim(ler) Seçiniz.</label
                                >
                              </div>
                              <div class="input-group-append">
                                <span class="input-group-text">Yükle</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                <div class="basic-form">
                  <form>
                    <div class="form-group">
                      <VueEditor v-model="BelediyeGenelBilgiler.kvkk" />
                    </div>
                  </form>
                </div>

                   <p>Yüklü Olanlar</p>
                        <div class="right-body">
                          <div class="message">
                            <div class="son-images">
                              <div class="inside-img">
                                <img
                                  v-for="(resim,
                                  index) in BelediyeGenelBilgiler.Dosyalar"
                                  :key="index"
                                  :src="resim.dosyaurl"
                                  @click="
                                    silindex = index;
                                    showAlertResim = true;
                                  "
                                  alt
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="yazfonk()"
                >
                  Güncelle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "KVKK",
  components: {
    NavSide,
  },
  data() {
    return {
      BelediyeGenelBilgiler: {},
      kontrol: true,
      Dosyalar: [],
      resimler: [],
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Genel");
    this.BelediyeGenelBilgiler = this.$store.getters.getBelgenel;
  },
  methods: {
    async yazfonk() {
      if (this.BelediyeGenelBilgiler.kvkk == "") {
        this.$root.$refs.toast.toastgoster("hata", "KVKK Bilgileri Giriniz !!");
      } else if (this.Dosyalar.length > 0) {
            const dataekle = {
  
              dosyakontrol: true,
              dosyalar: this.resimler,
              col: "Genel",
              key: "Genel",
              dataekle: {
                Dosyalar: this.Dosyalar,
              },
            };
            this.bodykont = false;
            this.prekontrol = true;
            await this.$store.dispatch("veriguncelledosyali", dataekle);
      } else { 
        const dataekle = {
          col: "Genel",
          key: "Genel",
          dataekle: this.BelediyeGenelBilgiler,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
        this.$root.$refs.toast.toastgoster("basari", "KVKK Bilgileri Güncellendi");
      }
    },
    dosyasecme(e) {
      const files = e.target.files;
      this.Dosyalar = files;
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>