<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>

    <div id="main-wrapper">
      <NavSide sayfa="Mesajlar" />
      <div class="content-body">
        <link href="vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
        <link
          href="vendor/datatables/css/jquery.dataTables.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css"
          rel="stylesheet"
        />
        <link href="css/style.css" rel="stylesheet" />
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table
                  id="example5"
                  class="table display mb-4 dataTablesCard fs-14"
                >
                  <thead>
                    <tr>
                      <th>Gönderen Adı</th>
                      <th>E-posta</th>
                      <th>Tarih</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(mail, index) in Mailler" :key="index">
                      <td>{{ mail[1].adi }}</td>
                      <td>{{ mail[1].gidecekmail }}</td>
                      <td>{{ mail[1].today }}</td>

                      <td class="d-flex">
                        <a @click="duzenle(mail, index, 'duzenle')">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                            />
                            <path
                              d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                            />
                          </svg>
                        </a>
                        <a @click="duzenle(mail, index, 'sil')" class="ml-4">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.50195 5.99994H5.5156H21.6248"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.5361 5.99994V3.99994C8.5361 3.46951 8.74826 2.9608 9.12589 2.58573C9.50352 2.21065 10.0157 1.99994 10.5498 1.99994H14.5771C15.1111 1.99994 15.6233 2.21065 16.0009 2.58573C16.3786 2.9608 16.5907 3.46951 16.5907 3.99994V5.99994M19.6112 5.99994V19.9999C19.6112 20.5304 19.399 21.0391 19.0214 21.4142C18.6438 21.7892 18.1316 21.9999 17.5975 21.9999H7.52928C6.99522 21.9999 6.48304 21.7892 6.10541 21.4142C5.72778 21.0391 5.51563 20.5304 5.51562 19.9999V5.99994H19.6112Z"
                              stroke="#A0A0A0"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-body" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Mesaj Oku</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <h5>Adı</h5>
                    <p>{{ Maildetay[1].adi }}</p>
                  </div>
                  <div class="basic-form">
                    <div class="form-group">
                      <h5>E-Posta</h5>
                      <p>{{ Maildetay[1].gidecekmail }}</p>
                    </div>
                  </div>
                  <div class="basic-form">
                    <div class="form-group">
                      <h5>Telefon</h5>
                      <p>{{ Maildetay[1].gidecekPhone }}</p>
                    </div>
                  </div>
                  <div class="basic-form">
                    <div class="form-group">
                      <h5>Mesaj</h5>
                      <p>{{ Maildetay[1].mesaj }}</p>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showAlert">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Mesajı Sil</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showAlert = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    {{ Mailler.adi }} isimli mesajı silmek istiyor musunuz ?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="sil()">
                    Evet SiL
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showAlert = false"
                  >
                    VAZGEÇ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { db } from "@/components/yardimcilar/firebaseinit";
import NavSide from "@/components/home/nav-side.vue";

export default {
  name: "Mailler",
  data() {
    return {
      Mailler: [],
      selectedIndex: Number,
      maillist: [],
      Maildetay: [],
      domainId: this.$store.state.domainId,
      showModal: false,
      showAlert: false,
      kontrol: false,
    };
  },
  async created() {
    this.getData()
  },
  components: {
    NavSide,
  },
  methods: {
    async getData() {
    db.collection("Mailler")
      .where("domainId", "==", this.domainId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.Mailler.push([doc.id, doc.data()]);
        });
        this.Mailler.reverse();
      });
    },
    async sil() {
      console.log(this.Maildetay);
      
      db.collection("Mailler")
        .doc(this.Maildetay[0])
        .delete()
        .then(async () => {

          // await this.getData()
        });
      this.Mailler.splice(this.selectedIndex, 1)
      //this.Mailler.remove(this.selectedIndex)
      this.showAlert = false;
      this.$root.$refs.toast.toastgoster("basari", "Mesaj Silindi");
      // this.Mailler = []
      this.mailler = this.$store.getters.getMailler;
      this.maillist = Object.values(this.Mailler);
    },

    duzenle(mail, index, hangisi) {
      this.Maildetay = mail;
      this.selectedIndex = index
      if (hangisi == "sil") {
        this.showAlert = true;
      } else if (hangisi == "duzenle") {
        this.showModal = true;
      }
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.secbtn {
  padding-top: 20px;
}
</style>