<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Başkan Görevler" />
      <div class="content-body">
        <div class="container-fluid">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Başkan Görevler</h4>
              </div>
              <div class="card-body">
                <div class="basic-form">
                  <form>
                    <div class="form-group">
                      <VueEditor v-model="BaskanBilgileri.gorev" />
                    </div>
                  </form>
                </div>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="yazfonk()"
                >
                  Güncelle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "BaskanGorev",
  components: {
    NavSide,
  },
  data() {
    return {
      BaskanBilgileri: {},
      kontrol: true,
    };
  },
  async created() {
    await this.$store.dispatch("vericekme", "Baskan");
    this.BaskanBilgileri = this.$store.getters.getBaskan;
  },
  methods: {
    async yazfonk() {
      if (this.BaskanBilgileri.gorev == "") {
        this.$root.$refs.toast.toastgoster("hata", "Görev Giriniz !!");
      } else {
        const dataekle = {
          col: "Baskan",
          key: "Baskan",
          dataekle: this.BaskanBilgileri,
        };
        await this.$store.dispatch("veriguncelle", dataekle);
        this.$root.$refs.toast.toastgoster("basari", "Görevler Güncellendi");
      }
    },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>